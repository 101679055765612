module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"undefined.solutions","short_name":"undefined","start_url":"/","background_color":"#fafafa","theme_color":"#131415","display":"minimal-ui","icon":"src/images/logos/undefined_lettermark_black.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129401708-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"siteSpeedSampleRate":10},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
